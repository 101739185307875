var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.cliente)?_c('div',{staticClass:"container-fluid p-0"},[_c('footer',{staticClass:"it-footer mt-5",attrs:{"id":"footer"}},[_c('div',{staticClass:"it-footer-main",style:(_vm.styleObject)},[_c('div',{staticClass:"container"},[(
                        _vm.configs.portale_pubblico.footer &&
                            _vm.configs.portale_pubblico.footer.logo
                    )?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 footer-items-wrapper logo-wrapper"},[_c('div',{staticClass:"it-brand-wrapper text-truncate"},[(_vm.cliente)?_c('img',{staticClass:"ue-logo",attrs:{"src":_vm.logo,"width":"200px","alt":'logo' + _vm.$t('cliente.nome') ==
                                        'cliente.nome'
                                            ? _vm.cliente.Nome
                                            : _vm.$t('cliente.nome')}}):_vm._e(),_c('div',{staticClass:"it-brand-text"},[_c('h2',{staticClass:"no_toc"},[(_vm.cliente)?_c('router-link',{staticClass:"navbar-brand",attrs:{"to":_vm.to}},[_vm._v(_vm._s(_vm.$t("cliente.nome") == "cliente.nome" ? _vm.cliente.Nome : _vm.$t("cliente.nome")))]):_vm._e()],1)])])])]),(
                            _vm.configs.portale_pubblico.footer &&
                                _vm.configs.portale_pubblico.footer.content
                        )?_c('div',{staticClass:"row"},[[_c('div',{domProps:{"innerHTML":_vm._s(
                                    _vm.configs.portale_pubblico.footer &&
                                        _vm.configs.portale_pubblico.footer
                                            .content
                                )}})]],2):_vm._e()]:_vm._e(),_c('div',{staticClass:"row mb-2 link-bar p-3 border-top border-white"},[_c('div',{staticClass:"col-12 col-md-9 footer-items-wrapper"},[_c('div',{staticClass:"footer-bottom text-white"},[_c('div',{staticClass:"links"},[(
                                        _vm.configs.portale_pubblico.footer &&
                                            _vm.configs.portale_pubblico.footer
                                                .links
                                    )?_c('router-link',{staticClass:"text-white px-2",class:_vm.configs.portale_pubblico.footer &&
                                        _vm.configs.portale_pubblico.footer
                                            .links
                                            ? 'border-right border-white'
                                            : '',attrs:{"to":{
                                        name: 'StatisticheAccesso'
                                    },"href":"#"}},[_vm._v(_vm._s(_vm.$t("statistiche_accesso.title"))+" ")]):_vm._e(),(
                                        _vm.configs.portale_pubblico.footer &&
                                            _vm.configs.portale_pubblico.footer
                                                .links
                                    )?_vm._l((_vm.configs
                                            .portale_pubblico.footer.links),function(link,label,i){return _c('a',{key:link,staticClass:"text-white px-2",class:i != Object.keys &&
                                            _vm.configs.portale_pubblico
                                                .footer &&
                                            _vm.configs.portale_pubblico.footer
                                                .links.length - 1
                                                ? 'border-right border-white'
                                                : '',attrs:{"href":link,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t(label)))])}):_vm._e()],2)])]),_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{staticClass:"credits d-flex flex-column mt-2 mt-md-0"},[(!_vm.hide_logo )?_c('a',{staticClass:"mx-auto",attrs:{"href":"https://www.easystaff.it/"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../../src/assets/images/logo_transparent.png"),"alt":"logo easystaff","width":"100px"}})]):_vm._e(),_c('p',{staticClass:"text-center w-100 text-white test-small",class:!_vm.hide_logo && 'mt-3'},[_vm._v(" Powered by EasyStaff - "+_vm._s(new Date().getFullYear())+" - "+_vm._s(_vm.version.replace(/"/g, ""))+" ")])])])])],2)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }