import store from "../store";
import router from "../router";
import axios from "axios";
import Cookies from "js-cookie";
export default () => {
    const http = axios.create({
        baseURL: process.env.VUE_APP_SERVER_URL,
        withCredentials: true // Abilita l'invio e la ricezione dei cookie
    });
    http.defaults.headers.common["X-APP-LOCALE"] =
        localStorage.getItem("locale") ||
        process.env.VUE_APP_I18N_LOCALE ||
        "it";
    http.defaults.headers.common['X-CLIENTE'] = store ? store.state.Ente.cliente : null;

    http.interceptors.response.use(
        r => r,
        function(error) {
            let {
                response: {
                    data: { message, data }
                },
                statusText
            } = error;
            if (statusText === undefined) statusText = "message";
            const goHomeMessages = ["reservation_not_found"];
            if (goHomeMessages.includes(message)) statusText = "home";
            let formattedError = { message, data };
            store.commit("Utils/SET_ERRORS", formattedError);
            if (
                router.history.current.name == "HomeCliente" ||
                router.history.current.name == "Home"
            ) {
                router.push({ name: "Error" });
            }
            throw error;
        }
    );
    Cookies.set("locale", document.querySelector("html").getAttribute("lang"));

    return http;
};
