<template>
    <div class="container-fluid p-0" v-if="cliente">
        <footer class="it-footer mt-5" id="footer">
            <div class="it-footer-main" :style="styleObject">
                <div class="container">
                    <template
                        v-if="
                            configs.portale_pubblico.footer &&
                                configs.portale_pubblico.footer.logo
                        "
                        name="logo-contenuto"
                    >
                        <div class="row">
                            <div
                                class="col-12 footer-items-wrapper logo-wrapper"
                            >
                                <div class="it-brand-wrapper text-truncate">
                                    <img
                                        class="ue-logo"
                                        v-if="cliente"
                                        :src="logo"
                                        width="200px"
                                        :alt="
                                            'logo' + $t('cliente.nome') ==
                                            'cliente.nome'
                                                ? cliente.Nome
                                                : $t('cliente.nome')
                                        "
                                    />
                                    <div class="it-brand-text">
                                        <h2 class="no_toc">
                                            <router-link
                                                v-if="cliente"
                                                class="navbar-brand"
                                                :to="to"
                                                >{{
                                                    $t("cliente.nome") ==
                                                    "cliente.nome"
                                                        ? cliente.Nome
                                                        : $t("cliente.nome")
                                                }}</router-link
                                            >
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="row"
                            v-if="
                                configs.portale_pubblico.footer &&
                                    configs.portale_pubblico.footer.content
                            "
                        >
                            <template>
                                <div
                                    v-html="
                                        configs.portale_pubblico.footer &&
                                            configs.portale_pubblico.footer
                                                .content
                                    "
                                ></div>
                            </template>
                        </div>
                    </template>
                    <div class="row mb-2 link-bar p-3 border-top border-white">
                        <div class="col-12 col-md-9 footer-items-wrapper">
                            <div class="footer-bottom text-white">
                                <div class="links">
                                    <router-link
                                        v-if="
                                            configs.portale_pubblico.footer &&
                                                configs.portale_pubblico.footer
                                                    .links
                                        "
                                        :to="{
                                            name: 'StatisticheAccesso'
                                        }"
                                        href="#"
                                        class="text-white px-2"
                                        :class="
                                            configs.portale_pubblico.footer &&
                                            configs.portale_pubblico.footer
                                                .links
                                                ? 'border-right border-white'
                                                : ''
                                        "
                                        >{{ $t("statistiche_accesso.title") }}
                                    </router-link>

                                    <template
                                        v-if="
                                            configs.portale_pubblico.footer &&
                                                configs.portale_pubblico.footer
                                                    .links
                                        "
                                    >
                                        <a
                                            :href="link"
                                            class="text-white px-2"
                                            :class="
                                                i != Object.keys &&
                                                configs.portale_pubblico
                                                    .footer &&
                                                configs.portale_pubblico.footer
                                                    .links.length - 1
                                                    ? 'border-right border-white'
                                                    : ''
                                            "
                                            target="_blank"
                                            v-for="(link, label, i) in configs
                                                .portale_pubblico.footer.links"
                                            :key="link"
                                            >{{ $t(label) }}</a
                                        >
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3">
                            <div
                                class="credits d-flex flex-column mt-2 mt-md-0"
                            >
                                <a
                                    href="https://www.easystaff.it/"
                                    class="mx-auto"
                                    v-if="!hide_logo "
                                >
                                    <img
                                        class="img-fluid"
                                        src="../../../src/assets/images/logo_transparent.png"
                                        alt="logo easystaff"
                                        width="100px"
                                /></a>

                                <p
                                    class="text-center w-100 text-white test-small"
                                    :class="!hide_logo && 'mt-3'"
                                >
                                    Powered by EasyStaff -
                                    {{ new Date().getFullYear() }} -
                                    {{ version.replace(/"/g, "") }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import { colors } from "../../mixins/colors";

export default {
    mixins: [colors],

    name: "EasyFooter",
    computed: {
        hide_logo() {
            return process.env.VUE_APP_HIDE_EASYSTAFF_LOGO || false;
        },
        version() {
            return process.env.VUE_APP_VERSION;
        },
        styleObject() {
            if (this.$route.matched.some(({ name }) => name === "Home")) {
                return {};
            }
            return {
                backgroundColor: this.colors[0],
                color: this.textColor
            };
        },
        logo() {
            if (this.cliente.Logo) {
                return `${process.env.VUE_APP_SERVER_URL}/storage/${this.cliente.Logo}`;
            }
            return `${process.env.VUE_APP_SERVER_URL}/storage/headerLogoGeneral.jpg`;
        },
        to() {
            return this.$route.matched.some(({ meta }) => !meta.external) ||
                (this.cliente && this.cliente.Codice)
                ? {
                      name: "HomeCliente",
                      params: { ente: this.cliente.Codice }
                  }
                : "/welcome";
        }
    }
};
</script>

<style></style>
